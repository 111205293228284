import React from "react";
import "./styles.css";
import { formatDate } from "../../lib/helper";

const InventoryTable = ({ items, addToCart, cartItems }) => {
  return (
    <div className="catalog-table">
      {items ? (
        <table className="table table-striped table-inventory">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Description</th>
              <th scope="col">ITEM #</th>
              <th scope="col">Reference #</th>
              <th scope="col">PO #</th>
              <th scope="col">Received</th>
              <th scope="col">Avail.</th>
              <th scope="col">Received Date</th>
              <th scope="col">Internal Ref ID</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => {
             
              return (
                <tr key={item.receiveItemId}>
                  <td>{item.description}</td>
                  <td>{item.itemIdentifier.sku}</td>
                  <td>{item.referenceNum}</td>
                  <td>{item.poNum}</td>
                  <td>{item.received}</td>
                  <td>{item.available}</td>
                  <td>{formatDate(item.receivedDate)}</td>
                  <td>{item.receiveItemId}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => addToCart(item)}
                    >
                      Add to Cart
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        "No results found."
      )}
    </div>
  );
};

export default InventoryTable;
