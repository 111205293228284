import React from "react";
import "./styles.css";
import { formatDate } from "../../lib/helper";
const InventoryList = ({ items, addToCart, cartItems }) => {
  return (
    <div className="catalog-list">
      {items
        ? items.map((item) => {console.log('item ', item)
        return (
            <div key={item.receiveItemId} className="catalog-item">
              <div className="item-image">{/* Add your item image here */}</div>
              <div className="item-details">
                <div className="item-title bg-secondary text-light text-center">
                  <div className="text-light font-weight-bold">
                    Description:
                  </div>
                  <div>{item.description}</div>
                </div>
                <div className="item-small-spacer"></div>
                <p className="item-sku item-p mt-3">
                  <span className="font-weight-bold">ITEM ID(sku):</span>
                  {item.itemIdentifier.sku}
                </p>
                <p className="item-sku item-p">
                  <span className="font-weight-bold"> Reference #: </span>
                  {item.referenceNum}
                </p>

                <div className="item-small-spacer"></div>
                <p className="item-available">
                  <span className="font-weight-bold">PO number: </span>
                  {item.poNum}
                </p>
                <p className="item-received">
                  <span className="font-weight-bold">Received: </span>
                  {item.received}
                </p>
                <p className="item-available">
                  <span className="font-weight-bold">Available: </span>
                  {item.available}
                </p>

                {/* <p className="item-on-hand">On Hand: {item.onHand}</p> */}
                <div className="item-small-spacer"></div>
                {/* <div className=" my-2 ">
                <p className="item-p ">
                  <span className="font-weight-bold">Weight</span> (US):
                  {item.weightImperial}
                </p>
                <p className="item-p ">
                  <span className="font-weight-bold">Weight</span> (Metric):
                  {item.weightMetric}
                </p>
              </div> */}
                <p className="item-p">
                  <div className="strong font-weight-bold">Received Date: </div>
                  {formatDate(item.receivedDate)}
                </p>

                <p className="item-p mt-4 pb-3">
                  <div className="strong font-weight-bold">
                    Internal Ref Id (receiveItemId):
                  </div>
                  {item.receiveItemId}
                </p>

                <div className="bottom-spacer"></div>
                <div className="d-flex flex-row align-items-center justify-content-between bottom-div ">
                  <div>
                    <img
                      src="/images/delivery.png"
                      title="Delivery Available"
                    />
                    {/* <div className="d-flex flex-column align-items-center"> */}
                    <div className="text-muted">We Deliver</div>
                    {/* <div className="pl-1">Available</div> */}
                    {/* </div> */}
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => addToCart(item)}
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>)}
          )
        : "No results found."}
    </div>
  );
};

export default InventoryList;
